import { Avatar, Badge, Button, Descriptions, Divider, List, Modal, Space, Tag, Tooltip, Typography } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { stripHtml } from "../../helpers/stripHtml"
import { tabFactory } from "../../helpers/tabsFactory"
import { generateKey } from "../../hooks/generateKey"
import { useUpdateTrainingMutation } from "../../network/useTraining"
import { TagFactory } from "../TagFactory"

export const TrainingBookingCard = ({ item, person, forceStatus = null, setRefetch, isAdmin }) => {
  const [acceptLoading, setacceptLoading] = useState(false)
  const [rejectLoading, setrejectLoading] = useState(false)
  const updateMutation = useUpdateTrainingMutation(item?.docId)
  const trainingUid = tabFactory("trainingRequestUid").get()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (item?.status === "pending" && item?.docId === trainingUid) setVisible(true)
  }, [item, trainingUid])

  const handleBooking = async (status) => {
    status === "requested" ? setacceptLoading(true) : setrejectLoading(true)
    try {
      updateMutation?.mutateAsync(
        {
          data: {
            status,
          },
        },
        { onSettled: () => (setRefetch ? setRefetch(true) : null) }
      )
      setacceptLoading(false)
      setrejectLoading(false)
      setVisible(false)
    } catch (error) {
      setacceptLoading(false)
      setrejectLoading(false)
      setVisible(false)
    }
  }
  return (
    <>
      <Modal
        width={window.innerWidth > 700 ? 600 : window.innerWidth}
        onCancel={() => {
          setVisible(false)
          tabFactory("trainingRequestUid").onChange("")
        }}
        visible={visible}
        footer={null}
      >
        <List.Item
          style={{
            borderRadius: "0.625rem",
            marginBottom: "1rem",
          }}
          actions={[
            <Space key="activityActions">
              <Tag color="green">Training</Tag>
            </Space>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item?.training?.imageUrl} />}
            title={
              <Space>
                {item?.training?.courseName}
                <TagFactory status={item?.status}>{item?.status?.toSentenceCase()}</TagFactory>
              </Space>
            }
            description={
              <Space direction="vertical">
                <Divider />
                <Typography.Title level={5}>Members</Typography.Title>
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  {item.members.map((member) => (
                    <Tooltip key={generateKey(member.uid)} title={member.name}>
                      <Avatar src={member.imageUrl} />
                    </Tooltip>
                  ))}
                </Avatar.Group>
                <Divider />
                <Descriptions title="Training" column={1}>
                  <Descriptions.Item label="Course Name">{item?.training?.courseName}</Descriptions.Item>
                  <Descriptions.Item label="Training Provider">{item?.training?.trainingProvider}</Descriptions.Item>
                  <Descriptions.Item label="Provider Contact Person">
                    {item?.training?.providerContactPerson}
                  </Descriptions.Item>
                  <Descriptions.Item label="Provider Email Address">{item?.training?.providerEmail}</Descriptions.Item>
                  <Descriptions.Item label="Provider Number">
                    +{item?.training?.providerNumber?.code}
                    {item?.training?.providerNumber?.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="Course Type">{item?.training?.traingRequestType}</Descriptions.Item>
                  <Descriptions.Item label="Dates">
                    {item?.training?.dates?.map((date) => dayjs(date)?.format("DD/MM/YYYY"))?.join(" -> ")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Accommodation">{item?.training?.accomodation}</Descriptions.Item>
                  <Descriptions.Item label="Accommodation Type">{item?.training?.accomodationType}</Descriptions.Item>
                  <Descriptions.Item label="Weekend Accommodation">
                    {item?.training?.accomodationWeekend}
                  </Descriptions.Item>
                  <Descriptions.Item label="Breakfast">{item?.training?.breakfast}</Descriptions.Item>
                  <Descriptions.Item label="Lunch">{item?.training?.lunch}</Descriptions.Item>
                  <Descriptions.Item label="Dinner">{item?.training?.dinner}</Descriptions.Item>
                  <Descriptions.Item label="Dietary Requirements">{item?.training?.diet || ""}</Descriptions.Item>
                  <Descriptions.Item label="Flight Required">{item?.training?.flight}</Descriptions.Item>
                  <Descriptions.Item label="Car Required">{item?.training?.car}</Descriptions.Item>
                </Descriptions>
                {item?.status === "pending" && isAdmin && (
                  <Space>
                    <Button
                      onClick={() => handleBooking("rejected")}
                      type="danger"
                      size="small"
                      disabled={acceptLoading}
                      loading={rejectLoading}
                    >
                      Reject
                    </Button>
                    <Button
                      loading={acceptLoading}
                      disabled={rejectLoading}
                      onClick={() => handleBooking("requested")}
                      type="primary"
                      size="small"
                    >
                      Approve
                    </Button>
                  </Space>
                )}
                <Badge
                  color="purple"
                  text={
                    <span className="badge--meta">
                      Date Range: {dayjs(item?.date[0]).format("DD/MM/YYYY")} -{" "}
                      {dayjs(item?.date[1]).format("DD/MM/YYYY")}
                    </span>
                  }
                />
              </Space>
            }
          />
        </List.Item>
      </Modal>
      <List.Item
        style={{
          border: item?.assignedToUid === person?.uid ? "1px solid rgba(11, 175, 224, 0.2)" : "",
          borderRadius: "0.625rem",
          marginBottom: "1rem",
        }}
        actions={[
          <Space className="hide--aboveMedium" key="activityActions">
            <Tag color="green">Training</Tag>
            <Avatar.Group
              maxCount={2}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
            >
              {item.members.map((member) => (
                <Tooltip key={generateKey(member.uid)} title={member.name}>
                  <Avatar src={member.imageUrl} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Space>,
        ]}
      >
        <List.Item.Meta
          style={{
            width: "55%",
          }}
          avatar={<Avatar style={{ backgroundColor: "#7265e6" }} />}
          title={
            <Space>
              {item?.training?.courseName}
              <TagFactory status={item?.status}>{item?.status?.toSentenceCase()}</TagFactory>
            </Space>
          }
          description={
            <Space direction="vertical">
              <Descriptions title="Training" column={2}>
                <Descriptions.Item label="Course Name">{item?.training?.courseName}</Descriptions.Item>
                <Descriptions.Item label="Training Provider">{item?.training?.trainingProvider}</Descriptions.Item>
                <Descriptions.Item label="Provider Contact Person">
                  {item?.training?.providerContactPerson}
                </Descriptions.Item>
                <Descriptions.Item label="Provider Email Address">{item?.training?.providerEmail}</Descriptions.Item>
                <Descriptions.Item label="Provider Number">
                  +{item?.training?.providerNumber?.code}
                  {item?.training?.providerNumber?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Course Type">{item?.training?.traingRequestType}</Descriptions.Item>
                <Descriptions.Item label="Dates">
                  {item?.training?.dates?.map((date) => dayjs(date)?.format("DD/MM/YYYY"))?.join(" -> ")}
                </Descriptions.Item>
                <Descriptions.Item label="Accommodation">{item?.training?.accomodation}</Descriptions.Item>
                <Descriptions.Item label="Accommodation Type">{item?.training?.accommodationType}</Descriptions.Item>
                <Descriptions.Item label="Weekend Accommodation">
                  {item?.training?.accomodationWeekend}
                </Descriptions.Item>
                <Descriptions.Item label="Breakfast">{item?.training?.breakfast}</Descriptions.Item>
                <Descriptions.Item label="Lunch">{item?.training?.lunch}</Descriptions.Item>
                <Descriptions.Item label="Dinner">{item?.training?.dinner}</Descriptions.Item>
                <Descriptions.Item label="Dietary Requirements">{item?.training?.diet || ""}</Descriptions.Item>
                <Descriptions.Item label="Flight Required">{item?.training?.flight}</Descriptions.Item>
                <Descriptions.Item label="Car Required">{item?.training?.car}</Descriptions.Item>
              </Descriptions>

              {isAdmin && (item?.status === "pending" || item?.status === "requested") && (
                <Space>
                  <Button
                    onClick={() => handleBooking("declined")}
                    type="danger"
                    size="small"
                    disabled={acceptLoading}
                    loading={rejectLoading}
                  >
                    Reject
                  </Button>
                  <Button
                    loading={acceptLoading}
                    disabled={rejectLoading}
                    onClick={() =>
                      handleBooking(forceStatus && item?.status !== "requested" ? forceStatus : "requested")
                    }
                    type="primary"
                    size="small"
                  >
                    Approve
                  </Button>
                </Space>
              )}
              <Badge
                color="purple"
                text={
                  <span className="badge--meta">
                    Date Range: {dayjs(item?.date[0]).format("DD/MM/YYYY")} -{" "}
                    {dayjs(item?.date[1]).format("DD/MM/YYYY")}
                  </span>
                }
              />
            </Space>
          }
        />
      </List.Item>
    </>
  )
}
