import { Typography } from "antd"
import React, { useState } from "react"
import { CertificateDrawerWithFetch } from "./CertificateDrawerWithFetch"

export const ProfileCertificate = ({ enrolment, children }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CertificateDrawerWithFetch
        //
        visible={visible}
        onClose={() => setVisible(false)}
        enrolment={enrolment}
        enrolmentUid={enrolment?.uid}
      />
      {children ? children : <Typography.Link onClick={() => setVisible(true)}>View</Typography.Link>}
    </>
  )
}
